import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { urlServicio } from '../url';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class OrdenCargaService {

  constructor(
    private http: HttpClient,
    private usuarioS: UsuarioService
  ) { }

  listarDesenturnes(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'clavijero/listarDesenturnes';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        console.error( error );reject( error )} );
    });
  }

  enturnar( motivo: string, vehiculo: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'clavijero/enturnar';
      const data = {
        motivo,
        vehiculo,
        usuario: this.usuarioS.usuario.id
      };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        console.error( error );reject( error )} );
    });
  }

  desenturnar( motivo: string, vehiculo: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'clavijero/desenturnar';
      const data = {
        motivo,
        vehiculo,
        usuario: this.usuarioS.usuario.id
      };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        console.error( error );reject( error )} );
    });
  }

  consultarPlaca( placa: string, pagina: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'solicitud/consultarPlaca/';
      const data = { placa, pagina }
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        console.error( error );reject( error )} );
    });
  }

  listarClavijero(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'clavijero/listar';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  enviar( solicitud: any, form: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'solicitud/enviarOrden';
      const data = { solicitud: JSON.stringify( solicitud ), form: JSON.stringify( form ) };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  actualizarVehiculos(): Promise<any>{
    return new Promise( (resolve, reject) => {
      const url = 'solicitud/obtenerVehiculos';
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  agregar( solicitud: any, ruta: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'solicitud/agregar';
      const data = { solicitud: JSON.stringify( solicitud ), ruta };
      console.log( data );
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  eliminar( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'solicitud/eliminar/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  asignarSiguiente( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'solicitud/siguienteAsignacion/' + id;
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  ver( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'solicitud/ver/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listarTipoCarga(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'solicitud/listarTipoCarga';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  obtenerOrdenCarga( numero: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'solicitud/obtener/' + numero;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  listar( pagina: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'solicitud/listar/' + pagina;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

}

import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class PushService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
  ) {
    this.angularFireMessaging.messaging.subscribe( (_messaging: any) => {
      _messaging._next = (payload: any) => {
        alert( payload.notification.title + '. ' + payload.notification.body );
    };
    _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    } );
  }

  requestPermission(): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      this.angularFireMessaging.requestToken.subscribe( (token) => {
        resolve( token );
      }, (err) => {
        reject( err );
      } );
    } );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe( (payload) => {
    console.log("new message received. ", payload);
    this.currentMessage.next(payload);
    } );
  }

}

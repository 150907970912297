import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { urlServicio } from '../url';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    private http: HttpClient
  ) { }

  guardarValorCombustible( valor: number ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'configuracion/guardarValorCombustible';
      const data = { valor };
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  cargarValorCombustible(): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'configuracion/valorCombustible';
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listarDepartamento(): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'departamento/listar';
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listarCiuades( id ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'ciudad/listar/' + id;
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

}

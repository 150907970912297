import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { urlServicio } from '../url';

@Injectable({
  providedIn: 'root'
})
export class PeajeService {

  constructor(
    private http: HttpClient
  ) { }

  eliminar( id: number ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'peaje/eliminar/' + id;
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    } );
  }

  editar( peaje: any, id: number ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'peaje/editar/' + id;
      const data = { peaje: JSON.stringify( peaje ) }
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    } );
  }

  ver( id: number ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'peaje/ver/' + id;
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    } );
  }

  agregar( peaje: any ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'peaje/agregar';
      const data = { peaje: JSON.stringify( peaje ) }
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    } );
  }

  listar(): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'peaje/listar';
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    } );
  }

}
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { urlServicio } from '../url';

@Injectable({
  providedIn: 'root'
})
export class ConsultaService {

  constructor(
    private http: HttpClient
  ) { }

  verSolicitud( id: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'consulta/verSolicitud';
      const data = { id };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  listarSolicitud( id: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'consulta/listarSolicitud';
      const data = { id };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { urlServicio } from '../url';

@Injectable({
  providedIn: 'root'
})
export class CapacitacionService {

  constructor(
    private http: HttpClient
  ) { }

  enviarIntento( usuario: any, capacitacion: any, calificacion: any, respuestas: any, numero: any, evaluacion: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/enviarIntento';
      const data = { usuario, capacitacion, calificacion, respuestas, numero, evaluacion };
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  verEvaluacion( usuario: number, capacitacion: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/verEvaluacion';
      const data = { usuario, capacitacion };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  consultarIdentificacion( identificacion: number, capacitacion: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/consultarIdentificacion';
      const data = { identificacion, capacitacion };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  eliminarPregunta( id: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/eliminarPregunta';
      const data = { id };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  guardarPregunta( id: number, pregunta: string ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/guardarPregunta';
      const data = { id, pregunta };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  agregarPregunta( capacitacion: number, pregunta: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/agregarPregunta';
      const data = { capacitacion, pregunta };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  eliminarRespuesta( id: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/eliminarRespuesta';
      const data = { id };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  agregarRespuesta( pregunta: number, respuesta: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/agregarRespuesta';
      const data = { pregunta, respuesta };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  verPreguntas( id: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/verPreguntas';
      const data = { id };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  editar( id: any, capacitacion: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/editar/' + id;
      const data = { capacitacion: JSON.stringify( capacitacion ) };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  consultar( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/consultar/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  verPresentacion( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/verPresentacion/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  generarReporteOpinion( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/generarReporteOpinion/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  generarReporte( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/generarReporte/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  editarUsuarios( id: any, usuarios: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/editarUsuarios/' + id;
      const data = { usuarios: JSON.stringify( usuarios ) };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  consultarUsuarios( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/consultarUsuarios/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  eliminar( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/eliminar/' + id;
      this.http.delete<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  ver( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/ver/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  listar(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/listar';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  agregar( capacitacion, preguntas, usuarios ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/agregar';
      const data = {
        capacitacion: JSON.stringify( capacitacion ),
        preguntas: JSON.stringify( preguntas ),
        usuarios: JSON.stringify( usuarios )
      };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  listarUsuarios(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'capacitacion/listarUsuarios';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

}

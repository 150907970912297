import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncriptarService {

  constructor() { }

  set( value: any ) {
    const key = CryptoJS.enc.Utf8.parse('H6t2ztq85eosDOPJ');
    const iv = CryptoJS.enc.Utf8.parse('H6t2ztq85eosDOPJ');
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse( value.toString() ),
      key,
      {
          keySize: 128 / 8,
          iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      }
    );
    return encrypted.toString();
  }

  get( value: any ) {
    const key = CryptoJS.enc.Utf8.parse('H6t2ztq85eosDOPJ');
    const iv = CryptoJS.enc.Utf8.parse('H6t2ztq85eosDOPJ');
    const decrypted = CryptoJS.AES.decrypt(
      value,
      key,
      {
        keySize: 128 / 8,
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PushService, UsuarioService } from './services/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'tscasanare';
  message;

  constructor(
    router: Router,
    private pushService: PushService,
    usuarioService: UsuarioService
  ) {
    //pushService.receiveMessage();
    usuarioService.cargarStorage().then( resp => {

    });
  }

  ngOnInit() {
   this.pushService.requestPermission()
     /* this.pushService.receiveMessage()
    this.message = this.pushService.currentMessage; */
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'asignacion', loadChildren: () => import('./pages/asignacion/asignacion.module').then( m => m.AsignacionModule ) },
  { path: 'formacion', loadChildren: () => import('./pages/formacion/formacion.module').then( m => m.FormacionModule ) },
  { path: 'consulta', loadChildren: () => import('./pages/consulta/consulta.module').then( m => m.ConsultaModule ) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

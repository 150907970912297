import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { urlServicio } from '../url';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(
    private http: HttpClient
  ) { }

  listarTodosDepartamentos( ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/listarTodosDepartamentos/';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  eliminar( id ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/eliminar/' + id;
      this.http.delete<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  editar( cliente: any, direcciones: any, id: any ):Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/editar/' + id;
      const data = { cliente: JSON.stringify(cliente), direcciones: JSON.stringify( direcciones ) };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  eliminarDireccion( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/eliminarDireccion/' + id;
      this.http.delete<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  consultar( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/consultar/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  agregar( cliente: any, direcciones: any ):Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/agregar';
      const data = { cliente: JSON.stringify(cliente), direcciones: JSON.stringify( direcciones ) };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  buscar( identificacion: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/buscar/' + identificacion;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listar(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/listar';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  editarIdentificacion( nombre: any, identificacion: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/editarIdentificacion';
      const data = { nombre, identificacion };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  buscarGenerador( identificacion: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/buscarGenerador/' + identificacion;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  listarDepartamentosXNombre( nombre: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/listarDepartamentosXNombre/';
      const data = { nombre };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  listarDepartamentos( identificacion: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/listarDepartamentos/' + identificacion;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  listarCiudades( departamento: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/listarCiudades/' + departamento;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  listarDirecciones( identificacion: any, ciudad: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cliente/listarDirecciones/' + identificacion + '/' + ciudad;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

}

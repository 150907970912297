import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { urlServicio, urlServicio2 } from '../url';

@Injectable({
  providedIn: 'root'
})
export class AsignacionService {

  constructor( private http: HttpClient ) { }

  agregarViaje( info: any ) {
    return new Promise<any>( (resolve, reject) => {
      const url = urlServicio + 'asignacion/agregarViaje';
      const data = { info }
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

  rechazar( tipo: string, id: number, usuario: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'asignacion/rechazar';
      const data = { tipo, id, usuario }
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

  autorizar( tipo: string, id: number, usuario: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'asignacion/autorizar';
      const data = { tipo, id, usuario };
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

  ver( id: number, usuario: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'asignacion/ver/' + id + '/' + usuario;
      this.http.get<any>(url).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

  cargarArchivo( asignacion: number, tipo: string, imagen: any, nombreArchivo: string ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio2 + 'asignacion/cargarArchivo/' + tipo + '/' + asignacion;
      this.http.post<any>( url, imagen ).subscribe( resp => {
        if ( !resp.error ) {
          const url = `${urlServicio}/asignacion/cargarArchivo/${tipo}/${asignacion}`;
          const data = {
            nombre: nombreArchivo
          }
          this.http.post<any>( url, data ).subscribe( resp => {
            if ( !resp.error ) {
              resolve(resp);
            } else {
              reject( resp );  
            }
          }, error => {
            reject( error );
          });          
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { urlServicio, urlServicio2 } from '../url';
import { EncriptarService } from '../encriptar/encriptar.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  usuario: any = {};
  token = '';

  constructor(
    private http: HttpClient,
    private encriptarService: EncriptarService
  ) { }

  buscarConductor( identificacion: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'usuario/buscarConductor/' + identificacion;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  buscarPropietario( identificacion: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'usuario/buscarPropietario/' + identificacion;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listarOpcionesAgregarUsuarios(): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'usuario/listarOpcionesAgregarUsuarios';
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listar(): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'usuario/listar';
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  eliminar( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'usuario/eliminar';
      const data = { id };
      this.http.post<any>(url, data).subscribe( resp => {
          if ( !resp.error ) {
            resolve(resp);
          } else {
            reject(resp);
          }
        }, error => reject(error) );
    });
  }

  eliminarDocumento( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'usuario/eliminarDocumento';
      const data = { id };
      this.http.post<any>(url, data).subscribe( resp => {
          if ( !resp.error ) {
            resolve(resp);
          } else {
            reject(resp);
          }
        }, error => reject(error) );
    });
  }

  agregarDocumento( documento: any, id: any, form: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      let url = urlServicio2 + 'usuario/subirDocumento/' + id ;
      this.http.post<any>(url, documento).subscribe( resp => {
        if ( !resp.error ) {
          const data = { documento: JSON.stringify( form ) };
          url = urlServicio + 'usuario/crearDocumento/' + id ;
          this.http.post<any>(url, data).subscribe( resp2 => {
            if ( !resp2.error ) {
              resolve( resp2 );
            } else {
              reject(resp2);
            }
          }, error => reject(error) );
        } else {
          reject(resp);
        }
      }, error => reject(error) );
    });
  }

  cambiarContrasena( contrasena: any, id: any ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'usuario/cambiarContrasena/' + id;
      const data = { contrasena };
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  guardar( usuario: any, id: any ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'usuario/guardar/' + id;
      const data = { usuario: JSON.stringify( usuario ) };
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  consultar( id: any ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'usuario/consultar/' + id;
      console.log( url );
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  agregar( usuario ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'usuario/agregar';
      const data = { usuario: JSON.stringify( usuario ) };
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  buscarUsuario( identificacion: any ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'usuario/buscarUsuario/' + identificacion;
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listarOpcionesAgregar(): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'usuario/listarOpcionesAgregar';
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listarConductores( ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'usuario/listarConductores';
      this.http.get<any>( url ).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  ingresar( usuario: any, token: string ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'usuario/ingresar/';
      const data = { usuario: JSON.stringify(usuario), token };
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( ! resp.error ) {
          this.usuario = resp.usuario;
          this.token = resp.token;
          this.guardarStorage();
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  guardarStorage() {
    localStorage.setItem( 'usuario', JSON.stringify( this.usuario ) );
    localStorage.setItem( 'token', this.token );
  }

  cargarStorage(): Promise<any> {
    return new Promise( ( resolve ) => {
      this.usuario = JSON.parse(  localStorage.getItem( 'usuario' ) );
      this.token = localStorage.getItem( 'token' );
      resolve( this.usuario );
    });
  }

}

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { urlServicio } from '../url';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  constructor(
    private http: HttpClient
  ) { }

  consultar( reporte: any ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      const url = urlServicio + 'reporte/consultar';
      const data = { reporte: JSON.stringify( reporte ) };
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( resp.error ) {
          reject( resp );
        } else {
          resolve( resp );
        }
      }, error => {
        reject( error );
      } );
    } );
  }

}

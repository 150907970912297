import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { urlServicio, urlServicio2 } from '../url';

@Injectable({
  providedIn: 'root'
})
export class TarjetaRutaService {

  constructor(
    private http: HttpClient
  ) { }

  eliminar( id: number ) : Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'tarjetaRuta/eliminar/' + id;
      this.http.get<any>( url ).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

  editar( tarjeta: any, id: number ) : Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'tarjetaRuta/editar/' + id;
      const data = { tarjeta: JSON.stringify( tarjeta ) };
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

  ver( id: number ) : Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'tarjetaRuta/ver/' + id;
      this.http.get<any>( url ).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

  listar(  ) : Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'tarjetaRuta/listar';
      this.http.get<any>( url ).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

  agregar( tarjeta: any ) : Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'tarjetaRuta/agregar';
      const data = { tarjeta: JSON.stringify( tarjeta ) };
      this.http.post<any>( url, data ).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

  agregarArchivo( archivo: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio2 + 'tarjetaRuta/agregarArchivo';
      this.http.post<any>( url, archivo ).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { urlServicio, urlServicio2 } from '../url';

@Injectable({
  providedIn: 'root'
})
export class RutaService {

  constructor(
    private http: HttpClient
  ) { }

  agregarTarjetaRuta( archivo: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio2 + 'ruta/agregarTarjeta';
      this.http.post<any>( url, archivo ).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

  editar( ruta: any, peajes: any, id: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'ruta/editar';
      const data = { ruta: JSON.stringify( ruta ), peajes: JSON.stringify(peajes), id };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  consultar( id: number ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      let url = urlServicio + 'ruta/consultar/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( !resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => {
        reject( error );
      });
    });
  }

  ver( id: number ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      let url = urlServicio + 'ruta/ver/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( !resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => {
        reject( error );
      });
    });
  }

  eliminar( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'ruta/eliminar';
      const data = { id };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listar(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'ruta/listar';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  buscarDestino( origen: any, destino: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'ruta/buscar/' + origen + '/' + destino;
      this.http.get<any>(url).subscribe( resp => {
        if ( !resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  buscarOrigen( origen: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'ruta/buscarOrigen/' + origen;
      this.http.get<any>(url).subscribe( resp => {
        if ( !resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  agregar( ruta: any, peajes: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'ruta/agregar';
      const data = { ruta: JSON.stringify( ruta ), peajes: JSON.stringify(peajes) };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  listarPeaje(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'ruta/listarPeaje';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

  buscarRuta( origen: any, destino: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'ruta/buscarRuta/' + origen + '/' + destino;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => reject( error ) );
    });
  }

}

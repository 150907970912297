import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { urlServicio, urlServicio2 } from '../url';

@Injectable({
  providedIn: 'root'
})
export class VehiculoService {

  constructor(
    private http: HttpClient,
  ) { }

  agregarVencimiento( vencimiento: any, id: any): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculo/agregarVencimiento';
      const data = { vencimiento, id };
      this.http.post<any>(url, data).subscribe( resp => {
          if ( ! resp.error ) {
            resolve( resp );
          } else {
            reject( resp );
          }
        }, error => reject( error ) );
    });
  }

  eliminarVencimiento( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculo/eliminarVencimiento';
      const data = { id };
      this.http.post<any>(url, data).subscribe( resp => {
          if ( ! resp.error ) {
            resolve( resp );
          } else {
            reject( resp );
          }
        }, error => reject( error ) );
    });
  }

  guardarTipoVehiculo( tipo: any ):Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculo/guardarTipo';
      const data = { tipo: JSON.stringify( tipo ) };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  cargarTipoVehiculo():Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculo/cargarTipo';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listarCargaTipo():Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'cargaTipo/listar';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listarRol():Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'rol/listarVehiculo';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listarTrailerMarca(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'trailerMarca/listar';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  listarTipo(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculoTipo/listar';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listarMarca(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculoMarca/listar';
      this.http.get<any>(url).subscribe( resp => {
          if ( ! resp.error ) {
            resolve( resp );
          } else {
            reject( resp );
          }
        }, error => reject( error ) );
    });
  }

  listarLinea( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculoLinea/listar/' + id;
      this.http.get<any>(url).subscribe( resp => {
          if ( ! resp.error ) {
            resolve( resp );
          } else {
            reject( resp );
          }
        }, error => reject( error ) );
    });
  }

  buscar( placa: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculo/buscar/' + placa;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  agregar( vehiculo: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculo/agregar';
      const data = { vehiculo: JSON.stringify(vehiculo) };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  listar(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculo/listar';
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  ver( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculo/ver/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject( error ) );
    });
  }

  consultar( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculo/consultar/' + id;
      this.http.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  editar( vehiculo: any , id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculo/editar/' + id;
      const data = { vehiculo: JSON.stringify(vehiculo) };
      this.http.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  subirDocumento( documento: any, id: any, form: any): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio2 + 'vehiculo/subirDocumento/' + id ;
      this.http.post<any>(url, documento).subscribe( resp => {
          if ( ! resp.error ) {
            const data = { documento: JSON.stringify(form) };
            const url2 = urlServicio + 'vehiculo/crearDocumento/' + id ;
            this.http.post<any>(url2, data).subscribe( resp2 => {
              if ( ! resp2.error ) {
                resolve( resp2 );
              } else {
                reject( resp2 );
              }
            }, error => reject( error ) );
          } else {
            reject( resp );
          }
        }, error => reject( error ) );
    });
  }

  eliminarDocumento( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculo/eliminarDocumento';
      const data = { id };
      this.http.post<any>(url, data).subscribe( resp => {
          if ( ! resp.error ) {
            resolve( resp );
          } else {
            reject( resp );
          }
        }, error => reject( error ) );
    });
  }

  eliminar( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'vehiculo/eliminar';
      const data = { id };
      this.http.post<any>(url, data).subscribe( resp => {
          if ( ! resp.error ) {
            resolve( resp );
          } else {
            reject( resp );
          }
        }, error => reject( error ) );
    });
  }

}

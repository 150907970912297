import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlServicio } from '../url';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {

  constructor(
    private http: HttpClient
  ) { }

  cargarVencimientoVehiculos( archivo: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'configuracion/cargarVencimientoVehiculos';
      this.http.post<any>( url, archivo ).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

  cargarTrinomio( archivo: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'configuracion/cargarTrinomio';
      this.http.post<any>( url, archivo ).subscribe( resp => {
        if ( !resp.error ) {
          resolve(resp);
        } else {
          reject(resp);
        }
      }, error => {
        reject(error);
      });
    });
  }

}

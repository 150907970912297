import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { urlServicio } from '../url';

@Injectable({
  providedIn: 'root'
})
export class DivulgacionService {

  constructor(
    private httpClient: HttpClient
  ) { }

  consultar( id: number ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'divulgacion/consultar/' + id;
      this.httpClient.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  editar( id: any, divulgacion: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'divulgacion/editar/' + id;
      const data = { divulgacion };
      this.httpClient.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  eliminar( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'divulgacion/eliminar/' + id;
      this.httpClient.delete<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  ver( id: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'divulgacion/ver/' + id;
      this.httpClient.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  agregar( divulgacion: any ): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'divulgacion/agregar';
      const data = {
        divulgacion
      };
      this.httpClient.post<any>(url, data).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

  listar(): Promise<any> {
    return new Promise( (resolve, reject) => {
      const url = urlServicio + 'divulgacion/listar';
      this.httpClient.get<any>(url).subscribe( resp => {
        if ( ! resp.error ) {
          resolve( resp );
        } else {
          reject( resp );
        }
      }, error => reject(error) );
    });
  }

}
